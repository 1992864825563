<template>
  <q-item class="item">
    <q-item-label>
      <q-item-label label class="name" @click.native="() => modal = true">
        {{ ticket.name }}
      </q-item-label>
      <q-item-label caption class="sub">
        {{ ticket.description }}
      </q-item-label>
      <q-item-label caption class="sub">
        {{ ticket.restriction }}
      </q-item-label>
    </q-item-label>
    <q-dialog v-model="modal">
      <q-card>
        <q-card-section>
          <div class="text-h6">
            {{ $t('train.summary.ticket_terms') }}
          </div>
        </q-card-section>
        <q-card-section>
          <q-list no-border>
            <q-item v-for="(t, i) in ticket.terms" :key="i">
              <q-item-section>
                <q-item-label label>
                  {{ t.title }}
                </q-item-label>
                <q-item-label caption>
                  {{ t.text }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-item>
</template>

<script>
export default {
  props: ['ticket'],
  data () {
    return {
      modal: false
    }
  }
}
</script>

<style lang="stylus" scoped>
  .item
    padding-left 0
    height: auto
  .name
    font-size 14px
    color #3367AB
    cursor: pointer
  .name:hover
    text-decoration: underline
  .sub
    font-size 13px
</style>
