export const railways = {
  'ALLIANCE RAIL': 'AR',
  'ARRIVA TRAINS WALES': 'AW',
  'TRANSPORT FOR WALES RAIL': 'AW',
  'C2C': 'CC',
  'CHILTERN RAILWAYS': 'CH',
  'CALEDONIAN SLEEPER': 'CS',
  'EAST MIDLANDS TRAINS': 'EM',
  'EAST MIDLANDS RAILWAY': 'EM',
  'EUROSTAR INTERNATIONAL': 'ES',
  'EUROSTAR': 'ES',
  'GRAND CENTRAL': 'GC',
  'GREAT NORTHERN': 'GN',
  'LNER': 'GR',
  'LONDON NORTH EASTERN RAILWAY': 'GR',
  'GREAT WESTERN RAILWAY': 'GW',
  'GATWICK EXPRESS': 'GX',
  'HULL TRAINS': 'HT',
  'HEATHROW EXPRESS': 'HX',
  'ISLAND LINE': 'IL',
  'GREATER ANGLIA': 'LE',
  'LEGGE INFRASTRUCTURE SERVICES': 'LG',
  'WEST MIDLANDS TRAINS': 'LM',
  'GREAT NORTH WESTERN RAILWAY': 'LN',
  'LONDON OVERGROUND': 'LO',
  'TRANSPORT FOR LONDON': 'LO',
  'LOCOMOTIVE SERVICES': 'LS',
  'MERSEYRAIL': 'ME',
  'NETWORK RAIL': 'NR',
  'NORTHERN RAIL': 'NT',
  'NORTHERN': 'NT',
  'NORTH YORKSHIRE MOORS RAILWAY': 'NY',
  'SOUTHEASTERN': 'SE',
  'STAGECOACH SUPERTRAM': 'SJ',
  'SOUTHERN': 'SN',
  'SWANAGE RAILWAY': 'SP',
  'SCOTRAIL': 'SR',
  'SOUTH WESTERN RAILWAY': 'SW',
  'GOVIA THAMESLINK RAILWAY': 'TL',
  'TRANSPENNINE EXPRESS': 'TP',
  'VINTAGE TRAINS': 'TS',
  'TYNE & WEAR METRO': 'TW',
  'VIRGIN TRAINS': 'VT',
  'WEST COAST RAILWAYS': 'WR',
  'CROSSCOUNTRY': 'XC',
  'CHRISTMAS SPECIAL': 'XM',
  'GLASGOW PRESTWICK AIRPORT': 'XP',
  'TFL RAIL/CROSSRAIL': 'XR',
  'STOBART RAIL': 'XS',
  'FREIGHT AND MISCELLANEOUS OPERATORS': 'ZZ'
}

export default railways
