<template>
  <q-card
    v-ripple
    class="shadow-1 cursor-pointer"
    :class="classes"
    @click="selected"
  >
    <div class="row q-col-gutter-xs">
      <div class="col-3 col-sm-2 q-py-md q-pl-md">
        <m-image :src="railwayLogo" height="56px" />
      </div>
      <div class="col-9 col-sm-6 q-pt-md q-pr-md text-faded">
        <div class="row bubble-top text-black">
          <div class="col-3 text-weight-medium">
            {{ train.depart.date | time }}
          </div>
          <div class="col-9">
            {{ train.origin.name | capitalise }}
          </div>
        </div>
        <div v-if="train.number_of_changes === 0" class="row bubble-line">
          <div class="col-9 offset-3 text-uppercase text-caption text-green">
            {{ $tc('num.changes', 0) }}
          </div>
        </div>
        <div
          v-for="(segment, key) in train.segments.data.slice(1)"
          v-else
          :key="key"
          class="row bubble-change bubble-line"
        >
          <div class="col-3 text-caption text-weight-medium">
            <span>{{ segment.depart.date | time }}</span>
          </div>
          <div class="col-9 text-caption">
            {{ segment.origin | capitalise }}
          </div>
        </div>
        <div class="row bubble-bottom text-black">
          <div class="col-3 text-weight-medium">
            {{ train.arrive.date | time }}
          </div>
          <div class="col-9">
            {{ train.destination.name | capitalise }}
          </div>
        </div>
        <div class="col-12 q-my-sm text-caption">
          <span title="Duration">{{ train.duration.text | toHoursMins }}</span>
          <span> &middot; </span>
          <span class="desktop-only" :title="$t('train.initial_vendor')">{{
            train.vendors[0] | capitalise
          }}</span>
          <span class="desktop-only"> &middot; </span>
          <span :title="$t('train.route_number')">{{
            train.segments.data[0].route_number
          }}</span>
        </div>
      </div>
      <div
        class="col-12 col-sm-4 column q-pt-md q-px-md q-pb-xs pricing text-faded justify-between"
        :title="`${train.ticket.description} - ${train.ticket.restriction}`"
      >
        <div class="row text-body2 text-blue-grey-5 flex items-center">
          <div class="col-12">
            <q-icon size="16px" name="mdi-ticket" /> &nbsp;&nbsp;{{
              train.ticket.name | capitalise
            }}
          </div>
          <div v-if="train.eticket_available" class="col-12 q-mt-md-xs">
            <q-icon size="16px" name="fas fa-qrcode" /> &nbsp;&nbsp;{{
              $t('train.eticket_available')
            }}
          </div>
        </div>
        <div
          class="q-mt-md"
          v-html="
            $t('from_price_per_ticket', { price: train.ticket.price.text })
          "
        />
      </div>
    </div>
    <q-separator />
    <div class="row">
      <q-list class="col">
        <q-expansion-item
          group="train-card-expansion-item"
          header-class="text-grey-8 bg-white"
          class="bg-white full-height"
          @click.stop
        >
          <template v-slot:header>
            <div class="full-width column justify-center">
              <div class="row full-width justify-between">
                {{ $t('timeline') }}
                <q-badge
                  v-if="multiModal"
                  class="q-mx-sm"
                  color="warning"
                  :title="$t('different_mode.journey.message')"
                >
                  {{ $t('different_mode.journey.title') }}
                </q-badge>
              </div>
            </div>
          </template>
          <q-timeline layout="loose" color="grey" class="q-pl-xs">
            <span v-for="(leg, index) in train.segments.data" :key="index">
              <q-timeline-entry
                :subtitle="leg.depart.date | time"
                :icon="timelineIcon(leg.mode.toLowerCase())"
                :side="leg.mode.toLowerCase() === 'train' ? 'right' : 'left'"
                :color="getTravelContentColor(leg.mode.toLowerCase())"
                class="text-dark"
              >
                <template v-slot:title>
                  <sup class="text-weight-regular">
                    <strong>{{ leg.origin }}</strong>
                    <br>
                    {{ leg.route_number || leg.mode }}
                  </sup>
                </template>
                <m-banner
                  v-if="leg.mode.toLowerCase() !== 'train'"
                  :title="$t('different_mode.leg.title')"
                  :message="$t('different_mode.leg.message')"
                  icon="warning"
                />
              </q-timeline-entry>
              <q-timeline-entry
                v-show="nextLegHasWait(index)"
                :side="leg.mode.toLowerCase() === 'train' ? 'left' : 'right'"
                :subtitle="leg.arrive.date | time"
              >
                <template v-slot:title>
                  <span class="text-subtitle2 text-faded">{{
                    legWaitTime(index)
                  }}</span>
                </template>
              </q-timeline-entry>
            </span>
            <q-timeline-entry
              :subtitle="train.arrive.date | time"
              class="text-dark"
            >
              <template v-slot:title>
                <sup class="text-dark text-weight-regular">
                  <strong>{{ train.destination.name }}</strong>
                  <br>
                  {{ $t('arrive_at_time', { time: date(train.arrive.date) }) }}
                </sup>
              </template>
            </q-timeline-entry>
          </q-timeline>
        </q-expansion-item>
      </q-list>
    </div>
  </q-card>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import date from 'utils/date-time'
import travelContents from 'mixins/travelContents'
import railways from 'utils/railway'
import { MImage, MBanner } from 'components/'

export default {
  filters: {
    time: value => date.toCivilTime(value),
    toHoursMins: value => value.replace(/ hours/, 'h').replace(/ minutes/, 'm').replace(/ hour/, 'h').replace(/ minute/, 'm'),
    capitalise: value => value[0].toUpperCase() + value.toLowerCase().slice(1)
  },
  components: {
    MImage,
    MBanner
  },
  mixins: [travelContents],
  props: {
    train: null,
    highlight: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      modal: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    isSelected () {
      return this.stash.selected.depart === this.train || this.stash.selected.return === this.train
    },
    classes () {
      return [this.isSelected ? 'selected bg-primary-selected shadow-3' : null, this.disabled ? 'disabled' : null]
    },
    disabled () {
      return date.newDate(this.train.depart.date) < date.newDate()
    },
    railwayLogo () {
      return `https://s3-eu-west-2.amazonaws.com/mobilleo-railwaylogos/${railways[this.train.vendors[0]] || 'XR'}_70.png`
    },
    multiModal () {
      return this.train.segments.data.some(leg => leg.mode.toLowerCase() !== 'train')
    }
  },
  methods: {
    date: val => val ? date.toCivilTime(val) : null,
    selected () {
      if (this.disabled) return
      this.$emit('click', this.train)
    },
    legWaitTime (index) {
      const legs = this.train.segments.data
      const lastLeg = index >= legs.length

      if (lastLeg) return null

      const currentLegArrival = date.newDate(legs[index].arrive.date)
      const nextLegDepart = date.newDate(legs[index + 1]?.depart.date)

      const diff = nextLegDepart.diff(currentLegArrival, 'minutes').toObject()

      return `${diff.minutes} minutes wait time`
    },
    nextLegHasWait (index) {
      const legs = this.train.segments.data
      const lastLeg = index >= legs.length

      const currentLegArrival = legs[index].arrive.date
      const nextLegDepart = legs[index + 1]?.depart.date
      const trainArrival = this.train.arrive.date

      if (lastLeg) {
        return currentLegArrival !== trainArrival
      }

      return (currentLegArrival !== nextLegDepart) && currentLegArrival !== trainArrival
    },
    timelineIcon (type) {
      const pref = this.getTravelPreferenceName(type)
      return `img:${require('../../../../assets/travel-icons/' + pref + '.svg')}`
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  @media (max-width 599px)
    border-bottom 1px solid #ccc

.pricing
  border-style solid
  border-color $grey-4
  border-radius 0 !important
  border-width 0

  @media (min-width $breakpoint-md-min)
    border-left-width 1px

  @media (max-width $breakpoint-sm-min)
    border-top-width 1px

@media (max-width $breakpoint-sm-min)
  .mobile-block
    display block

@media (min-width $breakpoint-md-min)
  .bubble-change, .bubble-top, .bubble-line, .bubble-bottom
    position relative
    padding-left 8px

    &:before, &:after
      position absolute
      content ''
      background-color #DCDCDC

  .bubble-change, .bubble-top, .bubble-bottom
    &:before
      width 8px
      height 8px
      left -12px
      border-radius 100%

  .bubble-change:before
    top 6px
    border-radius 0
    transform rotate(45deg)

  .bubble-top:before
    bottom 8px

  .bubble-bottom:before
    top 8px

  .bubble-line:after
    width 2px
    top -8px
    bottom -8px
    left -9px
</style>
