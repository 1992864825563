<template>
  <q-page padding>
    <div
      v-if="showDepart"
      class="row q-col-gutter-sm justify-center"
    >
      <div v-if="stash.depart.data.length" class="col-sm-12">
        <m-group-title :label="`${$t('train.choose.outbound')} - ${resultsDate} - ${adults}, ${children}`" />
      </div>
      <div v-if="$q.platform.is.mobile" class="earlier-later">
        <m-cta-button
          :loading="loadingearlier"
          :disabled="disableearlier"
          base-size="14px"
          @ctaClick="() => changeResults('earlier')"
        >
          {{ $t('time.earlier') }}
        </m-cta-button>
        <m-cta-button
          :loading="loadinglater"
          :disabled="disablelater"
          base-size="14px"
          @ctaClick="() => changeResults('later')"
        >
          {{ $t('time.later') }}
        </m-cta-button>
      </div>
      <div v-if="stash.depart.data.length" class="row">
        <div
          v-for="(train, i) in stash.depart.data"
          :key="train.journey_id"
          class="col-sm-12"
        >
          <train-card
            :train="train"
            @click="setDepart"
          />
          <help-card v-if="i === 2" />
        </div>
      </div>
      <m-empty-state v-else :style="{margin: 'auto'}" icon="train">
        {{ $t('no_trains_found') }}
      </m-empty-state>
    </div>

    <div
      v-else-if="showReturn"
      class="row q-col-gutter-sm justify-center"
    >
      <div class="col-sm-12">
        <m-group-title :label="`${$t('train.choose.return')} - ${resultsDate}`" />
      </div>
      <div v-if="$q.platform.is.mobile" class="earlier-later">
        <q-btn
          :loading="loadingearlier"
          :disabled="disableearlier"
          color="primary"
          class="time-button"
          @click="() => changeResults('earlier')"
        >
          {{ $t('time.earlier') }}
        </q-btn>
        <q-btn
          :loading="loadinglater"
          :disabled="disablelater"
          color="primary"
          class="time-button"
          @click="() => changeResults('later')"
        >
          {{ $t('time.later') }}
        </q-btn>
      </div>
      <div
        v-for="(train, i) in stash.return.data"
        :key="train.journey_id"
        class="col-sm-12"
      >
        <train-card
          :train="train"
          @click="setReturn"
        />
        <help-card v-if="i === 2" />
      </div>
    </div>

    <div v-else-if="stash.loading" class="loading">
      <q-spinner-dots size="60" />
    </div>

    <m-empty-state v-else icon="booking">
      {{ $t('no_bookings_found') }}
    </m-empty-state>

    <div class="cta">
      <m-cta-button
        v-if="stash.depart && leg === 'depart'"
        :label="stash.selected.depart ? $t('continue') : $t('select_an_option_to_continue')"
        :disabled="!stash.selected.depart"
        @ctaClick="stash.return ? $router.push({name: 'ondemand-train-return'}) : $router.push({name: 'ondemand-train-tickets'})"
      />
      <m-cta-button
        v-if="stash.return && leg === 'return'"
        :label="stash.selected.return ? $t('continue') : $t('select_an_option_to_continue')"
        :disabled="!stash.selected.return"
        @ctaClick="$router.push({name: 'ondemand-train-tickets'})"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'
import date from 'utils/date-time'
import store from 'store'
import { search, editSearch } from 'api/train'
import { mapGetters } from 'vuex'
import trainCard from './train-card'
import helpCard from '../../shared/help-card'
import i18n from 'i18n'
import { MEmptyState, MGroupTitle, MCtaButton } from 'components/'

export default {
  name: 'OndemandTrainResults',
  components: {
    trainCard,
    helpCard,
    MEmptyState,
    MGroupTitle,
    MCtaButton
  },
  props: {
    leg: {
      type: String,
      required: true,
      validator (value) {
        return ['depart', 'return'].includes(value)
      }
    }
  },
  data () {
    return {
      disableearlier: false,
      disablelater: false,
      loadingearlier: false,
      loadinglater: false
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    resultsDate () {
      const travelDate = date.newDate(this.stash[this.leg].data[0].depart.date)
      return date.toShortDate(travelDate)
    },
    adults () {
      return this.$tc('num.adults', this.stash.parameters.adults)
    },
    children () {
      return this.$tc('num.children', this.stash.parameters.children)
    },
    showDepart () {
      return this.leg === 'depart' && this.stash.depart?.data?.length
    },
    showReturn () {
      return this.leg === 'return' && this.stash.return?.data?.length
    }
  },
  methods: {
    setDepart (ticket, data) {
      const selected = this.stash.selected
      selected.depart = ticket
      store.dispatch('ondemand/stash', { selected })
    },
    setReturn (ticket, data) {
      const selected = this.stash.selected
      selected.return = ticket
      store.dispatch('ondemand/stash', { selected })
    },
    async changeResults (direction) {
      this.$store.dispatch('ondemand/stash', { loading: true })
      this['loading' + direction] = true
      const search = this.stash.searchToken
      const params = {
        include: this.stash.parameters.return ? 'outbound.segments,return.segments' : 'outbound.segments'
      }

      try {
        const { data } = await editSearch(search, this.leg, direction, params)

        const newResults = {
          depart: data.outbound,
          return: data.return || null,
          searchToken: data.search_token
        }

        this.$store.dispatch('ondemand/stash', newResults)
        this.$store.dispatch('ondemand/stash', { loading: false })

        this.disableearlier = false
        this.disablelater = false
        this['loading' + direction] = false
      } catch (err) {
        if (err.data.title === 'TrainlineBusinessErrorException') {
          this['disable' + direction] = true
          this['loading' + direction] = false
          this.$store.dispatch('ondemand/stash', { loading: false })
        }
        this.$q.notify({
          message: this.$t('error.search_token_expired'),
          type: 'negative'
        })
        this.$router.push('/on-demand/trains')
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const stash = store.getters['ondemand/stash']
    const partner = store.getters.partner
    const parameters = stash.parameters

    if (!stash.return) {
      loading.start({
        message: i18n.t('loading.searching.train'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      const params = {
        origin: parameters.origin.latLng || parameters.origin.value,
        destination: parameters.destination.latLng || parameters.destination.value,
        depart: date.toCivilDateTime(parameters.depart),
        ...(parameters.return && { return: date.toCivilDateTime(parameters.return) }),
        adults: parameters.adults,
        children: parameters.children || 0,
        include: parameters.return ? 'outbound.segments,return.segments' : 'outbound.segments',
        user: parameters.traveller.value,
        requester: parameters.requester.value,
        open_return: parameters.return_type === 'open_return',
        ...parameters.railcards
      }

      try {
        const { data } = await search(params)

        store.dispatch('ondemand/stash', {
          depart: data.outbound,
          return: data.return,
          searchToken: data.search_token,
          selected: {
            depart: data.outbound ? data.outbound.data[0] : null,
            return: data.return ? data.return.data[0] : null
          }
        })

        next()
      } catch (err) {
        handleErrors(err)
        next(false)
      } finally {
        loading.stop()
      }
    } else { next() }
  }
}
</script>

<style lang="stylus" scoped>
.vertical-rule
  max-width 1px !important
  margin-top 27px
  margin-bottom 7px
  background #c6c7c4

.horizontal-rule
  margin-left 8px
  margin-right 8px
  padding-bottom 5px
  border-bottom 1px solid #c6c7c4

.loading
  background rgba(244,244,244, .5)
  position absolute
  top 0
  right 150px
  bottom 0
  left 150px
  svg
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.earlier-later
  width 100%
  display flex
  justify-content space-around
</style>
