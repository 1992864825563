<template>
  <q-page v-if="summary" padding class="column content-center">
    <m-group-title :label="$t('select_ticket_delivery_option')" class="q-my-md" />
    <q-card
      v-if="etickets"
      class="cursor-pointer"
      :class="{
        'bg-primary-selected': eticketSelected,
        'bg-white': !eticketSelected,
        'text-faded': !eticketSelected,
        'text-primary': eticketSelected
      }"
      @click.native="selectEticket"
    >
      <q-card-section class="row">
        <div class="col-2 col-md-1">
          <q-icon name="fas fa-qrcode" left size="32px" />
        </div>
        <div class="col-10 col-md-11">
          <div class="text-h6">
            {{ $t('train.eticket_avoid_queues') }}
          </div>
          <div class="text-subtitle2 text-faded">
            {{ $t('train.tickets_attached_to_booking') }}
          </div>
        </div>
      </q-card-section>
    </q-card>

    <q-card
      class="cursor-pointer"
      :class="{
        'bg-primary-selected': !eticketSelected,
        'bg-white': eticketSelected,
        'text-faded': eticketSelected,
        'text-primary': !eticketSelected
      }"
      @click.native="selectCollection"
    >
      <q-card-section class="row">
        <div class="col-2 col-md-1">
          <q-icon name="fas fa-ticket-alt" left size="32px" />
        </div>
        <div class="col-10 col-md-11">
          <div class="text-h6">
            {{ $t('collect_ticket_from_station') }}
          </div>
          <div class="text-subtitle2 text-faded">
            {{ $t('train.tickets_collect_after_booking') }}
          </div>
        </div>
      </q-card-section>
    </q-card>

    <m-banner
      v-if="!eticketSelected && !hasKiosk"
      :title="$t('train.station_has_no_kiosk.title', { station_name: `${origin.name} (${origin.atoc})`})"
      class="help-notice"
      color="warning"
      icon="fas fa-exclamation-triangle"
    >
      <template v-slot:message>
        <div v-for="line in $t('train.station_has_no_kiosk.description')" :key="line" class="text-body2">
          {{ line }}
        </div>
        <div class="text-body2 text-link">
          <a :href="trainlineCollectionStationsURL" target="_blank">{{ $t('train.alternative_ticket_collection_stations') }}</a>
        </div>
      </template>
    </m-banner>

    <m-group-title :label="$t('train.seat_reservation')" class="q-my-md" />

    <q-card>
      <q-card-section class="row">
        <div class="col-9 text-body2">
          {{ $t('train.options.reserve_seat') }}
        </div>
        <div class="col-3 q-gutter-md">
          <q-radio v-model="seat_reservation" dense :val="true" :label="$t('yes')" />
          <q-radio v-model="seat_reservation" dense :val="false" :label="$t('train.options.no_seat_reservation')" />
        </div>
      </q-card-section>
      <q-separator v-if="seat_reservation" inset />
      <q-card-section v-if="seat_reservation" class="row">
        <div v-for="(label, key) in ['direction', 'environment', 'position']" :key="key" class="col-12 col-md-4">
          <div class="text-capitalize text-weight-medium">
            {{ $t(`train.options["${label}"]`) }}
          </div>
          <div>
            <q-radio v-model="options.seat[label]" :val="null" :label="$t('any')" />
            <q-radio
              v-for="item in summary.seat_prefs_list.filter(seat => seat.type.toLowerCase() === label)"
              :key="item.code"
              v-model="options.seat[label]"
              :val="item.code"
              :label="$t(`train.options.seat_prefs.${item.code}`)"
              class="flex"
            />
          </div>
        </div>
      </q-card-section>
      <q-separator inset />
      <q-card-section v-if="seat_reservation">
        <q-checkbox
          v-for="item in summary.seat_prefs_list.filter(seat => !['Direction', 'Environment', 'Position'].includes(seat.type))"
          :key="item.code"
          v-model="options.seat.options"
          :val="item.code"
          :label="$t(`train.options.seat_prefs.${item.code}`)"
        />
      </q-card-section>
    </q-card>

    <m-banner
      v-if="seat_reservation"
      :title="$t('train.cannot_guarantee_preferences')"
      class="help-notice padding"
      color="primary"
    />
    <div class="cta">
      <m-cta-button
        @ctaClick="$router.push({name: 'ondemand-train-summary'})"
      />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { handleErrors } from 'utils/utils'
import store from 'store'
import loading from 'utils/loading'
import { summary } from 'api/train'
import { mapGetters } from 'vuex'
import i18n from 'i18n'
import { MGroupTitle, MBanner, MCtaButton } from 'components/'

export default {
  name: 'OndemandTrainOptions',
  components: { MGroupTitle, MBanner, MCtaButton },
  data () {
    return {
      seat_reservation: true,
      options: {
        delivery: null,
        seat: {
          direction: null,
          position: null,
          environment: null,
          options: []
        }
      },
      trainlineCollectionStationsURL: 'https://business.support.thetrainline.com/en/support/solutions/articles/78000000022-which-stations-can-i-collect-my-tickets-from-in-the-uk-'
    }
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    summary () {
      return this.stash.summary
    },
    etickets () {
      return this.summary.delivery_options.filter(option => option.id === 'ETicket').length > 0
    },
    eticketSelected () {
      return this.options.delivery === 'ETicket'
    },
    origin () {
      return this.summary.journeys.outbound.origin
    },
    hasKiosk () {
      return this.origin.has_kiosk
    }
  },
  watch: {
    'seat_reservation' (value) {
      if (value === false) {
        this.options.seat = {
          direction: null,
          position: null,
          environment: null,
          options: []
        }
      }
    }
  },
  methods: {
    selectEticket () {
      this.options.delivery = 'ETicket'
    },
    selectCollection () {
      this.options.delivery = 'Collection'
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    const stash = store.getters['ondemand/stash']
    loading.start({
      message: i18n.t('loading.getting.ticket_summary'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await summary(stash.selected.ticket.booking_token)
      next(vm => {
        store.dispatch('ondemand/stash', { summary: data })
        vm.summary.seat_prefs_list = vm.summary.seat_prefs_list.filter(seat => seat.code !== 'INDL' && seat.code !== 'VIDO') // Filter first class seats as i cba with the label
        vm.options.delivery = vm.etickets ? 'ETicket' : 'Collection'

        store.dispatch('ondemand/stash', { delivery: vm.summary.delivery_options })

        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(false)
      loading.stop()
    }
  },
  beforeRouteLeave (to, from, next) {
    store.dispatch('ondemand/stash', this.options)
    next()
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  max-width 600px
  .desktop &
    width 600px
.q-radio.flex
  display flex !important // Override display: inline-flex
.help-notice
  max-width 620px
  margin 16px auto
  a
    color $primary
    text-decoration underline
    font-style italic
  &.padding
    margin-bottom 100px // just a stupid amount of padding so it's not hidden by the MOnDemandFooter when on mobile
</style>
