<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <q-card
        square
        class="bg-white"
      >
        <q-card-section :class="`bg-primary text-white text-uppercase`">
          {{ $t('where_are_you_travelling') }}
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="train-grid">
            <m-location-search
              ref="pickUp"
              :query="autocomplete"
              :value="parameters.origin"
              :label="$t('going_from')"
              icon="fa fa-location"
              class="origin search-btn"
              :placeholder="$t('choose_location_or_address')"
              no-shadow
              keep-open
              no-saved
              @selected="originSelect"
            />

            <q-btn
              flat
              class="icon swap"
              size="14px"
              icon="fa fa-exchange"
              color="primary"
              @click="swap"
            />

            <m-location-search
              ref="pickUp"
              :query="autocomplete"
              :value="parameters.destination"
              :label="$t('going_to')"
              icon="fa fa-map-marker"
              class="destination search-btn"
              :placeholder="$t('choose_location_or_address')"
              no-shadow
              keep-open
              no-saved
              @selected="destinationSelect"
            />

            <div class="row radio">
              <div class="col center">
                <q-radio
                  v-model="parameters.return_type"
                  :val="'one_way'"
                  :label="$tc('train.return_type.one_way')"
                />
              </div>
              <div class="col center">
                <q-radio
                  v-model="parameters.return_type"
                  :val="'return'"
                  :label="$tc('train.return_type.return')"
                />
              </div>
              <div class="col center">
                <q-radio
                  v-model="parameters.return_type"
                  :val="'open_return'"
                  :label="$tc('train.return_type.open_return')"
                />
              </div>
            </div>

            <div class="row q-col-gutter-sm adults">
              <div class="col">
                <q-select
                  v-model="parameters.adults"
                  :options="parameters.passengerNumbers"
                  class="col"
                  stack-label
                  emit-value
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                  :label="$t('train.options.adults')"
                  :error="!$v.belowPassengerLimit || !$v.hasPassengers"
                  :error-message="$t('train.errors.passengers')"
                />
              </div>
              <div class="col">
                <q-select
                  v-model="parameters.children"
                  :options="parameters.passengerNumbers"
                  class="col"
                  stack-label
                  emit-value
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                  :label="$t('train.options.children')"
                  :error="!$v.belowPassengerLimit || !$v.hasPassengers"
                  :error-message="$t('train.errors.passengers')"
                />
              </div>
            </div>

            <div class="row q-col-gutter-sm date">
              <div class="col-6">
                <m-date-field
                  v-model="parameters.depart"
                  :min="minDepart"
                  :label="$t('date.travel')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
              <div class="col-6">
                <m-time-field
                  v-model="parameters.depart"
                  :min="minDepart"
                  :minute-step="15"
                  :label="$t('time.from')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
            </div>

            <div v-if="parameters.return_type === 'return'" class="row q-col-gutter-sm returning">
              <div class="col-6">
                <m-date-field
                  v-model="parameters.return"
                  :min="minReturn"
                  :label="$t('date.return')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
              <div class="col-6">
                <m-time-field
                  v-model="parameters.return"
                  :min="minReturn"
                  :minute-step="15"
                  stack-label
                  :label="$t('time.return')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
            </div>
            <div v-else-if="parameters.return_type === 'open_return'" class="row q-col-gutter-sm returning">
              <div class="col-6">
                <m-date-field
                  :read-only-value="'Open Return'"
                  :label="$t('date.return')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
              <div class="col-6">
                <m-time-field
                  :read-only-value="'Open Return'"
                  stack-label
                  :label="$t('time.return')"
                  :outlined="$q.platform.is.mobile"
                  :rounded="$q.platform.is.mobile"
                />
              </div>
            </div>

            <railcard-select
              :railcard-options="railcardOptions"
              :adults="parameters.adults"
              type="railcard"
              class="railcards"
              @update="val => railcards = val"
            />
          </div>
        </q-card-section>
      </q-card>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" />
        </div>
      </div>
      <div class="cta">
        <m-cta-button
          :label="$tc('find.train', 2)"
          :disabled="$v.$invalid"
          @ctaClick="goSearch"
        />
      </div>
    </div>
  </q-page>
</template>

<script type="text/javascript">
import 'styles/ondemand.styl'
import { required, requiredIf, or } from 'vuelidate/lib/validators'
import { stations, railcards as getRailcards } from 'api/train'
import date from 'utils/date-time'
import model from './model'
import authentication from 'mixins/authentication'
import searchLocation from 'mixins/searchLocation'
import { MLocationSearch, MUnifiedUsers, MTimeField, MDateField, MCtaButton } from 'components/index'
import railcardSelect from './railcards'
import { handleErrors } from 'utils/utils'
const { addToDate, roundMinutesUp } = date

export default {
  components: {
    MTimeField,
    MDateField,
    MLocationSearch,
    MUnifiedUsers,
    MCtaButton,
    railcardSelect
  },
  mixins: [ authentication, searchLocation ],
  data () {
    return {
      parameters: model(),
      railcardOptions: [],
      railcards: []
    }
  },
  computed: {
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    },
    minDepart () {
      // Min departure param is (now + 15 minutes) rounded to the next 15 min
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15)
    },
    minReturn () {
      return addToDate(this.parameters.depart, { minutes: 15 })
    },
    showReturnFields () {
      return this.parameters.return_type === 'return'
    }
  },
  validations: {
    parameters: {
      origin: { required },
      destination: { required },
      depart: { required },
      return: { required: requiredIf(function () { return this.showReturnFields }) },
      traveller: { required },
      requester: { required }
    },
    hasPassengers: or(
      function () { return this.parameters.adults > 0 },
      function () { return this.parameters.children > 0 }
    ),
    belowPassengerLimit: function () {
      return this.parameters.adults + this.parameters.children <= 8
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    'parameters.depart' (val) {
      if (this.showReturnFields && this.parameters.return !== null && (this.parameters.depart > this.parameters.return)) {
        this.parameters.return = addToDate(this.parameters.depart, { hours: 4 })
      }
      if (val < this.minDepart) {
        this.parameters.depart = this.minDepart
      }
    },
    'parameters.return' (val) {
      if (val && val < this.minReturn) {
        this.parameters.return = this.minReturn
      }
    },
    '$route.query.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.origin = val
        }
      }
    },
    '$route.query.destination': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.parameters.destination = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
    this.parameters.depart = this.minDepart
  },
  async beforeMount () {
    const { data } = await getRailcards()
    this.railcardOptions = data
  },
  methods: {
    originSelect (value) {
      this.parameters.origin = value
    },
    destinationSelect (value) {
      this.parameters.destination = value
    },
    goSearch () {
      const searchParameters = { ...this.parameters }
      const reducer = (accumulator, { code }) => {
        if (Object.keys(accumulator).includes(code)) {
          accumulator[code]++
        } else if (code) {
          accumulator[code] = 1
        }
        return accumulator
      }

      const railcardsToMap = this.railcards.reduce(reducer, {})
      const railcardsToQuery = {}
      let i = 0
      for (const key in railcardsToMap) {
        railcardsToQuery[`railcards[${i}][code]`] = key
        railcardsToQuery[`railcards[${i}][qty]`] = railcardsToMap[key]
        i++
      }

      this.$store.dispatch('ondemand/stash', { parameters: { ...searchParameters, railcards: railcardsToQuery } })
      this.$router.push({ name: 'ondemand-train-depart' })
    },
    async autocomplete (terms) {
      try {
        const { data } = await stations({ query: terms })
        const stationList = data.map(station => {
          return {
            label: station.name,
            value: station.atoc,
            icon: 'train',
            color: 'blue-8',
            append: station.atoc
          }
        })
        return stationList
      } catch (err) {
        handleErrors(err)
      }
    },
    swap () {
      [this.parameters.destination, this.parameters.origin] = [
        this.parameters.origin,
        this.parameters.destination
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>

.center
  justify-content center
  display flex
</style>
