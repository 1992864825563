<template>
  <q-card
    v-ripple
    :class="classes"
    class="cursor-pointer"
    @click="$emit('click', ticket)"
  >
    <q-card-section class="row no-padding">
      <div class="col" style="padding: 16px">
        <h6>{{ ticket.tickets[0].name }}</h6>
        <span v-if="ticket.tickets.length > 1">
          <sub v-for="ticketItem in ticket.tickets" :key="ticketItem.id">
            {{ ticketItem.name }}+
          </sub>
        </span>
        <sub>{{ ticket.tickets[0].description }}</sub>
      </div>
      <div
        class="col-2 col-xs-4 column justify-center items-center content-center text-center bg-white"
      >
        <sub class="text-grey-7">{{
          $t('total_cost_for_passengers', { num: stash.parameters.adults + stash.parameters.children })
        }}</sub>
        <span class="text-h5">{{ ticket.total_price.text }}</span>
      </div>
    </q-card-section>
  </q-card>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
export default {
  props: {
    ticket: null
  },
  computed: {
    ...mapGetters({
      stash: 'ondemand/stash'
    }),
    isSelected () {
      return this.stash.selected.ticket === this.ticket
    },
    classes () {
      return this.isSelected ? 'selected bg-primary-selected text-primary' : ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  max-width 600px
  position relative

  @media (max-width 599px)
    width 100%
    margin 0
    border-bottom solid 1px #ccc

.text-h5
  font-size 1.3em
</style>
